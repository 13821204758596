
    import { Component, Vue } from 'vue-property-decorator'

    const ContactUs = () => import('./components/ContactUs.vue')
    const SearchBox = () => import('./components/SearchBox.vue')

    @Component({
        components: {
            ContactUs,
            SearchBox
        }
    })
    export default class App extends Vue {
        dialog = false
        dealerPortalUrl = ''
        drawer = false

        created() {
            this.dealerPortalUrl = process.env.VUE_APP_DEALER_PORTAL_URL ?? '';
        }
    }
