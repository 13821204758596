
import { Component, Vue } from 'vue-property-decorator'
const ProductCatalog = () => import('../components/ProductCatalog.vue')

@Component({
  components: { ProductCatalog }
})
export default class Home extends Vue {
  data() {
    return {
      items: [
        {
          src: 'https://soimagescdn.azureedge.net/fdp/carousel/carousel_1.jpg'
        },
        {
          src: 'https://soimagescdn.azureedge.net/fdp/carousel/carousel_2.jpg'
        },
        {
          src: 'https://soimagescdn.azureedge.net/fdp/carousel/carousel_3.jpg'
        },
        {
          src: 'https://soimagescdn.azureedge.net/fdp/carousel/carousel_4.jpg'
        }
      ]
    }
  }
}
