import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'

const About = () => import(/* webpackChunkName: "about" */ '../views/About.vue')
const ProductBrowsing = () => import(/* webpackChunkName: "product-catalog" */ '../views/ProductBrowsing.vue');
const ProductCatalog = () => import(/* webpackChunkName: "product-catalog" */ '../views/ProductCatalog.vue');
const Category = () => import(/* webpackChunkName: "product-catalog" */ '../views/Category.vue');
const SubCategory = () => import(/* webpackChunkName: "product-catalog" */ '../views/SubCategory.vue');
const Product = () => import(/* webpackChunkName: "product-catalog" */ '../views/Product.vue');
const WhereToBuy = () => import(/* webpackChunkName: "where-to-buy" */ '../views/WhereToBuy.vue');
const PrivacyPolicy = () => import(/* webpackChunkName: "privacy-policy" */ '../views/PrivacyPolicy.vue');
const Registration = () => import(/* webpackChunkName: "registration" */ '../views/registration/Registration.vue');

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/products',
    component: ProductBrowsing,
    children:[
      {
        path: '',
        name: 'ProductCatalog',
        component: ProductCatalog
      },
      {
        path: 'search-results',
        name: 'Search',
        component: SubCategory
      },
      {
        path: ':categorySlug',
        name: 'Category',
        component: Category
      },
      {
        path: ':categorySlug/:subCategorySlug',
        name: 'SubCategory',
        component: SubCategory
      },
      {
        path: ':categorySlug/:subCategorySlug/:productSlug',
        name: 'Product',
        component: Product
      },
    ]
  },
  {
    path: '/where-to-buy',
    name: 'WhereToBuy',
    component: WhereToBuy
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/registration',
    name: 'Registration',
    component: Registration
  },
  {
    path: '/contact',
    redirect: '/',
  }
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            const element = document.querySelector(to.hash);
            element?.scrollIntoView({ behavior: 'smooth' })
        }
    }
})

export default router
